<template>
  <apply-list class="content md" ref="applyList" :report="report" :query="query" v-if="info">
    <template v-slot:query="{form}">
      <a-form-model-item :label="report ? '汇报人' : '申请人'">
        <select-book type="user" v-model="form.applicant" @change="getList"></select-book>
      </a-form-model-item>
    </template>
    <template v-slot:button="{form}">
      <a-button type="primary" @click="exportData(form)" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
    </template>
  </apply-list>
</template>

<script>

import {getApplyStatusText} from "@/common/js/apply";
import {getTemple} from "@/common/js/storage";
import ApplyList from "./ApplyList";
export default {
  name: "TemplateDataComp",
  components: {
    ApplyList
  },
  data() {
    return {
      exporting: false
    }
  },
  computed: {
    info() {
      let temp = this.$store.getters.temp;
      return temp;
    },
    report() {
      let info = this.info;
      return info && info.is_oa == 1 && info.type == 3;
    },
    query() {
      let res = "";
      if(this.info && !this.report) {
        res += `&filter[temp_id]=${this.info.id}`
      }
      return res;
    },
  },
  methods: {
    getList() {
      this.$refs.applyList.getList();
    },
    exportData(form) {
      let info = this.info;
      if(this.exporting) return;
      this.exporting = true;
      let url = '/admin/live/export?';
      if(this.report) {
        url += 'is_report=1';
      } else {
        url += `temp_id=${info.id}`
      }
      url += `&temple_id=${getTemple()}`;
      let fileName = info.name;
      if(form.time && form.time.length == 2) {
        let start = form.time[0] + ' 00:00:00'
        let end = form.time[1] + ' 23:59:59'
        url += `&start_time=${start}&end_time=${end}`;
        fileName += `${form.time[0]}-${form.time[1]}`;
      }
      if(form.status > 0) {
        url += `&status=${form.status}`;
        fileName += getApplyStatusText(form.status);
      }
      if(form.applicant && form.applicant.length > 0) {
          url += `&user_id=${form.applicant[0]}`;
      }
      this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
        this.exporting = false;
        const content = res;
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) { // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName + '.xlsx';
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else { // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      }).catch(() => {
        this.exporting = false;
      });
    },
  }
}
</script>

<style scoped lang="less">

</style>
