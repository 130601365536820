<template>
  <div class="data-content">
    <a-card :bordered="false" title="人流数据">
      <router-link slot="extra" :to="{ name: 'SafeSmokeList' }" class="card-extra">
        查看更多<a-icon type="right" />
      </router-link>
      <div class="piece-area-ctx flex-box align-center justify-between">
        <div class="piece-view">
          <div class="piece-view-value">{{ info.total || 0 }}</div>
          <div class="piece-view-title">进客流量</div>
        </div>
        <div class="piece-view">
          <div class="piece-view-value">{{ info.online_count || 0 }}</div>
          <div class="piece-view-title">出客流量</div>
        </div>
        <div class="piece-view">
          <div class="piece-view-value">{{ info.online_rate || 0 }}</div>
          <div class="piece-view-title">当前在寺人数</div>
        </div>
      </div>
    </a-card>
    <a-card :bordered="false" class="data-piece">
      <div slot="title" class="flex-box align-center">
        <span>人流量统计</span>
        <div class="select-box flex-box">
          <div
            class="option-item"
            :class="{ active: option.value == trafficType }"
            v-for="option in hdmOptions"
            :key="option.value"
            @click="trafficSelect(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <chart :option="trafficOption" class="traffic-chart chart-box flex-grow" />
    </a-card>
    <a-card :bordered="false" class="data-piece">
      <div slot="title" class="flex-box align-center">
        <span>预警分析</span>
        <div class="select-box flex-box">
          <div
            class="option-item"
            :class="{ active: option.value == analysisType }"
            v-for="option in ymOptions"
            :key="option.value"
            @click="analysisSelect(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <router-link slot="extra" :to="{ name: 'SafeSmokeList' }" class="card-extra">
        查看详情<a-icon type="right" />
      </router-link>
      <div class="flex-box">
        <chart :option="levelOption" class="chart-box flex-grow" />
        <chart :option="countOption" class="chart-box flex-grow" />
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  ymOptions,
  hdmOptions,
  getLevalPieChartOption,
  getCountLineChartOption,
  getTrafficLineChartOption,
} from "../../common/constant/safe";

export default {
  name: "TrafficData",
  data() {
    return {
      type: 5, // 人流
      info: {},
      ymOptions,
      hdmOptions,
      analysisType: 1,
      trafficType: 1,
      levelOption: {},
      countOption: {},
      trafficOption: {},
    };
  },
  watch: {
    analysisType() {
      this.getData();
    },
    trafficType() {
      this.getTrafficData();
    },
  },
  created() {
    this.getData();
    this.getTrafficData();
  },
  methods: {
    // 获取人流量数据
    getTrafficData() {
      this.$axios({
        url: "/admin/security-work-order/people-statistics",
        method: "GET",
      }).then((res) => {
        if (res.error == 0) {
          let data = res.data || {};
          this.info = {
            total: data.enterNumber || 0,
            online_count: data.outNumber || 0,
            online_rate: data.now || 0,
          };
          let target = hdmOptions.find((item) => item.value == this.trafficType);
          let list = data[target.key];
          this.getTrafficChart(list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取预警分析数据
    getData() {
      let params = {
        type: this.type,
      };
      if (this.analysisType == 1) {
        params.month = 1;
      } else {
        params.year = 1;
      }
      this.$axios({
        url: "/admin/security-work-order/statistics",
        method: "GET",
        params,
      }).then((res) => {
        if (res.error == 0) {
          let data = res.data || {};
          let alarm_analysis = data?.alarm_analysis || {};
          let lineData = alarm_analysis?.line || [];
          this.getLevelChart(alarm_analysis);
          this.getAnalysisChart(lineData);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 预警等级
    getLevelChart(alarm_analysis) {
      let data = [
        {
          name: "一级预警",
          value: alarm_analysis.one || 0,
        },
        {
          name: "二级预警",
          value: alarm_analysis.two || 0,
        },
        {
          name: "三级预警",
          value: alarm_analysis.three || 0,
        },
        // {
        //   name: "四级预警",
        //   value: alarm_analysis.four || 0,
        // },
      ];
      this.levelOption = getLevalPieChartOption(data);
    },
    getDate(date, type) {
      if (type == 1) {
        let dateArr = date?.split("-");
        dateArr.shift();
        date = dateArr.join("-");
      }
      return date;
    },
    // 预警次数分析
    getAnalysisChart(lineData) {
      const data = lineData?.map((item) => {
        let date = this.getDate(item.date, this.analysisType);
        return {
          date: date,
          count: item.alarm_num,
        };
      });
      this.countOption = getCountLineChartOption(data);
    },
    // 人流量统计
    getTrafficChart(list) {
      const data = list?.map((item) => {
        if (this.trafficType == 1) {
          return {
            date: item.hour,
            in: item.addcount,
            out: item.reduce_count,
          };
        } else {
          return {
            date: item.date,
            in: item.enter_number,
            out: item.out_number,
          };
        }
      });
      this.trafficOption = getTrafficLineChartOption(data);
    },
    analysisSelect(option) {
      this.analysisType = option.value;
    },
    trafficSelect(option) {
      this.trafficType = option.value;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../common/less/safe.less");

.traffic-chart {
  height: 308px;
}
</style>
